// store/modules/userLoyaltyPromocodeList.js
const state = {
    filters: {
      pagination: {
        limit: 10,
        page: 1,
        pages: 1,
      },
      sortFilter: {
        options: [
          {
            text: 'Último creado',
            value: 'created_at'
          },
        //   {
        //     text: 'Cantidad de puntos totales',
        //     value: 'code_uses'
        //   },
        //   {
        //     text: 'Cantidad de puntos ganados',
        //     value: 'code_uses'
        //   },
        ],
        optionValue: 'created_at',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: -1,
      },
      searchFilter: {
        searchValue: '',
        options: [
          {
            text: 'Usuario',
            value: 'unique_id'
          },
          // {
          //   text: 'Usuario',
          //   value: 'promocode'
          // }
        ],
        optionValue: '',
      },
    },
    // Other states...
  };
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    // Other mutations...
  };
  
  const actions = {
    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
    // Other actions...
  };
  
  const getters = {
    filters: (state) => state.filters,
    // Other getters...
  };
  
  export default {
    namespaced: true, // Important for namespacing in Vuex
    state,
    mutations,
    actions,
    getters,
  };