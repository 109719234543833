export default [
  {
    path: '/corporates',
    name: 'corporates',
    meta: {
      requiresAuth: true,
      // permission: 'providers/providers-list',
      title: 'Corporativos',
      module: 'corporates',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-store',
      isBaseLayout: true
    },
    component: () =>
      import(
        /* webpackChunkName: "api-partners" */ 
        '@/views/corporates/CorporatesLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'corporates-list',
        meta: {
          adminLayoutNav: true,
          // permission: 'providers/providers-list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Lista de corporativos',
          module: 'corporates',
          icon: 'mdi-view-list',
          // providerStatus: 'all',
          allowedTotalDocumentsForIndexPages: true
        },
        component: () =>
          import(
            /* webpackChunkName: "shipment-partners" */ '@/views/corporates/CorporatesList.vue'
          )
      }
    ]
  }
];
