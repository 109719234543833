export default [
    {
      path: "/users-loyalty",
      name: "users-loyalty",
      meta: {
        requiresAuth: true,
        permission: "",
        title: "Lealtad de Usuarios",
        module: "users-loyalty",
        layout: "admin",
        adminLayoutNav: true,
        icon: "mdi-account-heart",
      },
      component: () =>
        import(
          /* webpackChunkName: "users-loyalty" */ "@/views/users-loyalty/UsersLoyaltyLayout.vue"
        ),
      children: [
        {
          path: "",
          name: "users-loyalty-level-index",
          meta: {
            adminLayoutNav: true,
            permission: "",
            requiresAuth: true,
            layout: "admin",
            title: "Niveles",
            module: "users-loyalty",
            icon: "mdi-stairs",
          },
          component: () =>
            import(
              /* webpackChunkName: "users-loyalty-level-index" */ "@/views/users-loyalty/UsersLoyaltyLevelList.vue"
            ),
        },
        {
          path: "history-points",
          name: "users-loyalty-history-index",
          meta: {
            adminLayoutNav: true,
            permission: "",
            requiresAuth: true,
            layout: "admin",
            title: "Historial de puntos",
            module: "users-loyalty",
            icon: "mdi-history",
          },
          component: () =>
            import(
              /* webpackChunkName: "users-loyalty-history-index" */ "@/views/users-loyalty/UsersLoyaltyHistoryPoints.vue"
            ),
        },
        {
          path: "promocode",
          name: "users-loyalty-promocode-index",
          meta: {
            adminLayoutNav: true,
            permission: "",
            requiresAuth: true,
            layout: "admin",
            title: "Promocode",
            module: "users-loyalty",
            icon: "mdi-ticket-percent",
          },
          component: () =>
            import(
              /* webpackChunkName: "users-loyalty-promocode-index" */ "@/views/users-loyalty/UsersLoyaltyPromocodeList.vue"
            ),
        },
        {
          path: "cashback",
          name: "users-loyalty-cashback-index",
          meta: {
            adminLayoutNav: true,
            permission: "",
            requiresAuth: true,
            layout: "admin",
            title: "Cashback",
            module: "users-loyalty",
            icon: "mdi-cash-refund",
          },
          component: () =>
            import(
              /* webpackChunkName: "users-loyalty-cashback-index" */ "@/views/users-loyalty/UsersLoyaltyCashbackList.vue"
            ),
        },
        {
          path: "users-loyalty-config",
          name: "users-loyalty-index",
          meta: {
            permission: "",
            requiresAuth: true,
            layout: "admin",
            title: "Configuración",
            module: "users-loyalty",
            icon: "mdi-tools",
            adminLayoutNav: true,
          },
          component: () => 
            import(
              /* webpackChunkName: "users-loyalty-index" */ "@/views/users-loyalty/UsersLoyaltyConfig.vue"
            ),
        },
      ],
    },
  ];
  